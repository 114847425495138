* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --poppins: "Poppins", sans-serif;
  --lato: "Lato", sans-serif;
  --light: #f9f9f9;
  --blue: #3c91e6;
  --light-blue: #cfe8ff;
  --grey: #eee;
  --dark-grey: #aaaaaa;
  --dark: #342e37;
  --red: #db504a;
  --yellow: #ffce26;
  --light-yellow: #fff2c6;
  --orange: #fd7238;
  --light-orange: #ffe0d3;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
}

button,
button:hover,
button:focus,
button:active {
  cursor: pointer;
  background: none;
  border: none;
}

li {
  list-style: none;
}

html {
  overflow-x: hidden;
}

body {
  background: var(--grey);
  overflow-x: hidden;
}

body.dark {
  --light: #0c0c1e;
  --grey: #060714;
  --dark: #fbfbfb;
}

.btn-main,
.btn-main:hover {
  height: 36px;
  padding: 0 16px;
  border-radius: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
}

.btn-main,
.btn-main:hover {
  background: var(--blue);
}

.btn-main .fa,
.btn-main .text {
  color: var(--light);
}
.badge {
  cursor: pointer;
}

/* CONTENT */
#content {
  position: relative;
  width: calc(100% - 200px);
  left: 200px;
  transition: 0.3s ease;
}
#sidebar.hide ~ #content {
  width: calc(100% - 60px);
  left: 60px;
}

/* MAIN */
#content main {
  width: 100%;
  padding: 36px 24px;
  font-family: var(--poppins);
  max-height: calc(100vh - 56px);
  overflow-y: auto;
}
#content main .head-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 5px;
  flex-wrap: wrap;
}
#content main .head-title .left h1 {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 5px;
  color: var(--dark);
}

#content main .head-title .right a {
  margin: 0px 5px;
}

::-webkit-scrollbar {
  width: 0px;
}

@media screen and (max-width: 768px) {
  #content {
    width: calc(100% - 60px);
    left: 200px;
  }
}

@media screen and (max-width: 576px) {
  #content main .box-info {
    grid-template-columns: 1fr;
  }
}

/* Table Styling */

#content main .table-data {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 24px;
  margin-top: 24px;
  width: 100%;
  color: var(--dark);
}
#content main .table-data > div {
  border-radius: 20px;
  background: var(--light);
  padding: 24px;
  overflow-x: auto;
}
#content main .table-data .head {
  display: flex;
  align-items: center;
  grid-gap: 16px;
  margin-bottom: 24px;
}
#content main .table-data .head h3 {
  margin-right: auto;
  font-size: 24px;
  font-weight: 600;
}
#content main .table-data .head .bx {
  cursor: pointer;
}

#content main .table-data .order {
  flex-grow: 1;
  flex-basis: 500px;
}

#content main .table-data .todo {
  flex-grow: 1;
  flex-basis: 200px;
}
#content main .table-data .todo-list {
  width: 100%;
  padding-left: 0rem;
}
#content main .table-data .todo-list li {
  width: 100%;
  margin-bottom: 16px;
  background: var(--grey);
  border-radius: 10px;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#content main .table-data .todo-list li p {
  margin: 0.5rem 0rem;
}
#content main .table-data .todo-list li .bx {
  cursor: pointer;
}
#content main .table-data .todo-list li:nth-child(odd) {
  border-left: 10px solid var(--blue);
}
#content main .table-data .todo-list li:nth-child(even) {
  border-left: 10px solid var(--orange);
}
#content main .table-data .todo-list li:last-child {
  margin-bottom: 0;
}

table th, table td{
  color: var(--dark);
}

@media screen and (max-width: 576px) {
  #content main .table-data .head {
    min-width: 420px;
  }
  #content main .table-data .order table {
    min-width: 420px;
  }
  #content main .table-data .todo-list {
    min-width: 420px;
  }
}

i{
  cursor: pointer;
}

table i{
  margin-left: 5px;
}

#todo a{
  color: var(--dark);
}